import React from "react";

// Components
import Layout from "../components/Layouts/Layout";
import SEO from "../components/Utilities/SEO";

const NotFoundPage = () => (
  <Layout>
    <SEO title="Page Not Found | Anana Vestige" description="" />
  </Layout>
);

export default NotFoundPage;
